.loading-bar {
  position: fixed;
  top: 0;
  left: 25px;
  height: inherit;
  background-color: #f11946;
  opacity: 1;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  z-index: 99999;
}

.loading-bar-full {
  position: fixed;
  top: 0;
  left: 25px;
  opacity: 0;
  height: inherit;
  transition: opacity 0.1s ease-out;
  -moz-transition: opacity 0.1s ease-out;
  -webkit-transition: opacity 0.1s ease-out;
  -o-transition: opacity 0.1s ease-out;
  z-index: 99999;
}
