/*Two Impulse Conversational AI STYLES*/
/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/poppins-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/poppins-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/poppins-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/poppins-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/poppins-v20-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
html {
  position: relative;
  min-height: 100%;
}

body {
  font-family: 'Poppins', 'Roboto', sans-serif;
  font-weight: 300;
  background-color: #f7f7f7;
  color: #999;
  margin-bottom: 60px;
}

.color-brand {
  color: #351549;
}

.color-brand-alt {
  color: #6904b7;
}

a,
.links {
  color: #351549;
  transition: all 0.3s;
  cursor: pointer;
}

a:hover,
a:focus,
.links:hover,
.links:focus {
  color: #c50434;
  text-decoration: none;
}

p a {
  color: #ffffff;
  text-decoration: underline;
}

small {
  font-weight: 300;
}

p a:hover,
p a:focus {
  color: #ffffff;
  opacity: 0.4;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 300;
  line-height: 1.5;
  color: #16181d;
}
.lh-1 {
  line-height: 1;
}
.fs-2 {
  font-size: 2rem;
}

.fs-15 {
  font-size: 1.5rem;
}

.transparent {
  background-color : transparent !important;
}

/*LOADING*/
.loading {
  height: 4px;
  background-color: #351549;
  position: fixed;
  width: 100%;
  margin-left: 250px;
  top: 72px !important;
  z-index: 10000;
}

.loading-login {
  margin-left: 0px;
  top: 0px !important;
}

.height-control {
  min-height: 100px;
  max-height: 300px;
  overflow-y: auto;
}
.active-mod-height-control {
  min-height: 374px;
  max-height: 374px;
  height: 374px;
  padding-bottom: 1rem;
  overflow-y: auto;
}
.pd-0 {
  padding: 0;
}

.bd-0 {
  border: 0;
}
.text-right-mobile-center {
  text-align: right;
}
/*NAVIGATION*/
.navbar {
  font-size: 0.9rem;
  padding: 0rem 30px 0rem 30px;
  height: 75px;
}
.navbar-light .navbar-nav .nav-link {
  color: #999;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #16181d;
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #16181d;
}

img.logo-robo {
  margin: 1.5rem 1rem;
}

.navbar-brand {
  width: 275px;
  height: 75px;
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 1rem;
  margin-left: -30px;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  background-color: #351549;
  vertical-align: middle;
}
.bg-light {
  background-color: #ffffff !important;
}
.page-title {
  margin-bottom: 0;
}

.export-buttons{
  margin-top: -2em;
}

#import-bot{
  margin-right: 5px;
}

/*Nav pills*/
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #351549;
}
.nav-pills .nav-link {
  border-radius: 0.5rem;
  background-color: #fff;
  color: #351549;
}
.nav-pills .nav-link:hover,
.nav-pills .nav-link:focus {
  color: #fff;
  background-color: #351549;
}

.nav-pills .nav-existing-intent.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ff3366;
}
.nav-pills .nav-existing-intent-intent {
  border-radius: 0.5rem;
  background-color: #fff;
  color: #ff3366;
}
.nav-pills .nav-existing-intent:hover,
.nav-pills .nav-existing-intent:focus {
  color: #fff;
  background-color: #ff3366;
}

.nav-pills .nav-new-intent.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #999;
}
.nav-pills .nav-new-intent {
  border-radius: 0.5rem;
  background-color: #fff;
  color: #999;
}
.nav-pills .nav-new-intent:hover,
.nav-pills .nav-new-intent:focus {
  color: #fff;
  background-color: #999;
}

/*Buttons*/
.btn {
  display: inline-block;
  font-weight: 300;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn.disabled, .btn:disabled{
  opacity: 0.35;
}


.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  line-height: 1.2;
  border-radius: 0.5rem;
}

.btn-primary {
  color: #351549;
  background-color: transparent;
  border-color: #351549;
}

.btn-primary:hover {
  color: #fff;
  background-color: #351549;
  border-color: #351549;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 51, 102, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #351549;
  border-color: #351549;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #351549;
  border-color: #351549;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 51, 102, 0.5);
}

.btn-secondary {
  color: #6904b7;
  background-color: transparent;
  border-color: #6904b7;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #6904b7;
  border-color: #6904b7;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(167, 170, 177, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6904b7;
  border-color: #6904b7;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6904b7;
  border-color: #6904b7;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(167, 170, 177, 0.5);
}
.btn-transparent {
  color: #351549;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
.btn-transparent:hover {
  color: #c50434;
  background-color: transparent;
  border: 0;
}

.btn-light {
  color: #16181d;
  background-color: #f7f7f7;
  border-color: #d4d9df;
}
.btn-light:hover {
  color: #999;
  background-color: #f7f7f7;
  border-color: #d4d9df;
}

.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 217, 223, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #999;
  background-color: #f7f7f7;
  border-color: #d4d9df;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #999;
  background-color: #f7f7f7;
  border-color: #d4d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 217, 223, 0.5);
}
.btn-icon {
  border: 0px;
  cursor: pointer;
  color: #351549;
  padding: 0 1rem;
  background-color: transparent;
}

.btn-upgrade {
  color: #fff;
  background-color: #351549;
  border-color: #351549;
}

.btn-upgrade:hover {
  color: #351549;
  background-color: transparent;
  border-color: #351549;
}

.btn-upgrade:focus,
.btn-upgrade.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 51, 102, 0.5);
}

.btn-upgrade.disabled,
.btn-upgrade:disabled {
  color: #fff;
  background-color: #351549;
  border-color: #351549;
}

.btn-upgrade:not(:disabled):not(.disabled):active,
.btn-upgrade:not(:disabled):not(.disabled).active,
.show > .btn-upgrade.dropdown-toggle {
  color: #fff;
  background-color: #351549;
  border-color: #351549;
}

.btn-upgrade:not(:disabled):not(.disabled):active:focus,
.btn-upgrade:not(:disabled):not(.disabled).active:focus,
.show > .btn-upgrade.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 51, 102, 0.5);
}

.pointer{
  cursor: pointer;
}

#create-organization{
  margin-bottom: 0.5em;
}

/*Tables*/

.table {
  font-size: 0.9rem;
  color: #999;
}

.table thead th {
  color: #16181d;
  vertical-align: bottom;
  border-bottom: 1px solid #e7e7e7;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e7e7e7;
}

.detail-row {
  padding: 0 !important;
  border-top: 0 !important;
}

.detail-row-content {
  padding: 1px;
}

.conversation-summary {
}

.conversation-summary b {
  color: #000;
}

.conversation-summary i {
  font-weight: 300;
}

.conversation-summary-table thead th,
.conversation-summary-table td {
  border: 0;
}

.robo-welcome-message-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.scroll-table tbody{
  display: block;
  max-height: 300px;
  width:100%;
  overflow-y: scroll;
}

.scroll-table thead {
  display: table;
  width: calc( 100% - 1em );
  table-layout: fixed;
}

.scroll-table tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.scroll-table table {
  width: 100%;
}

.scroll-table .dropdown {
  margin-left: 70%;
}

.duplicate {
  color: #ff3366;
}

/*Loader*/
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ff3366;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/*Toggle button*/
.toggle-status {
  text-align: center;
  width: 50px;
  margin-right: 0;
  float: right;
  cursor: pointer;
}
.toggle-status2 {
  text-align: center;
  width: 50px;
  margin-right: 0;
  float: none;
  cursor: pointer;
}
.statustable {
  font-size: 0.6rem;
}
/*Forms*/
.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.5;
  color: #16181d;
  background-color: #f7f7f7;
  background-clip: padding-box;
  border: 1px solid #d4d9df;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:focus {
  border: 2px solid #a7aab1;
  box-shadow: inset 0 0px 0px rgba(255, 51, 102, 0.075),
    0 0 0px rgba(255, 51, 102, 0.3);
}
.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0rem;
}
.invalid-feedback i {
  margin-top: 2px;
  font-size: 0.9rem;
}

.invalid-feedback-select{
  border-color: #dc3545;
}

.invalid-feedback-country-select{
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

div.react-datepicker-wrapper
  div.react-datepicker__input-container
  input.form-control {
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 0rem;
}

.form-margin{
  margin-top: 100px;
}

/*Login*/
.logo-login {
  margin: 90px auto 30px auto;
  text-align: center;
  width: auto;
}

.login button {
  alignment: center;
}

.alert.reset {
  display: none;
}
.container-login {
  max-width: 400px;
}
.container-public {
  max-width: 600px;
}
/*Alerts*/

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
/*Pagination*/
.page-link {
  position: relative;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.5;
  border-radius: 0.5rem;
  margin-left: 1px;
  color: #16181d;
  background-color: #f7f7f7;
  border: 2px solid #d4d9df;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.page-link:hover,
.page-link:focus {
  position: relative;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  margin-left: 1px;
  color: #999;
  background-color: #f7f7f7;
  border: 2px solid #d4d9df;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #351549;
  border-color: #351549;
}

/*Cards*/
.card {
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
}
.card-header:first-child {
  border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
}
.card-header {
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  background-color: #fff;
  color: #16181d;
  border-bottom: 1px solid #e7e7e7;
}
a.icon-header-card {
  font-size: 0.8rem;
  color: #999;
}
a.icon-header-card:hover,
a.icon-header-card:focus {
  color: #16181d;
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.5rem;
}
/*Modals*/
.modal-open .modal {
  background: rgba(247, 247, 247, 0.9);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 999999;
}
.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  outline: 0;
}
.modal-header {
  padding: 1rem 1.5rem;
  font-size: 1rem;
  border-bottom: 1px solid #e9ecef;
}
.modal-body {
  padding: 1rem 1.5rem;
}
.modal-footer {
  padding: 1rem 1.5rem;
  border-top: 1px solid #e9ecef;
  font-size: 1rem;
}

.wide-modal{
width: 60vw !important;
max-width: 60vw;
}


.wide-modal{
  width: 60vw !important;
  max-width: 60vw;
  }

@media (min-width: 576px){
  .wide-modal{
    width: 60vw !important;
    max-width: 60vw !important;
    }
}

/*Keys*/
.hidden {
  display: none;
}
/*Breadcrumbs*/
.breadcrumb {
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0rem;
}

/*Uploads*/
.upload-drag {
  border: 1px dashed #ccc;
  padding: 5rem;
}
/*Lists*/
.list-active-conv {
}
.list-active-conv .badge {
  line-height: 1.2;
}

.list-active-conv .list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: -1px;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-left: 0;
  border-right: 0;
  font-size: 0.9rem;
}
.list-active-conv .list-group-item:first-child {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  border-top: 0px solid rgba(0, 0, 0, 0.125);
}
.list-active-conv .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}
/*badges*/
.badge-primary {
  background-color: #6904b7;
}

/*Tabs*/
/*Dropdowns*/
.dropdown-menu {
  min-width: 10rem;
  padding: 0rem 0;
  margin: 0.13rem 0 0 !important;
  font-size: 0.9rem;
  color: #16181d;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
}
.dropdown-divider {
  margin: 0;
}
.dropdown-item:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}
.dropdown-item:last-child {
  border-radius: 0 0 0.25rem 0.25rem;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1.5rem;
  clear: both;
  font-weight: 300;
  color: #16181d;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  text-decoration: none;
  background-color: #6904b7;
}

.nv-point{
  display: none;
}

/* ---------------------------------------------------
    CHAT
----------------------------------------------------- */

/* overide css for loader */
.robo #robo-loading {
  bottom: 30% !important;
  left: 13% !important;
}

.test-container{
  display: flex;
  flex-direction: row;
  max-height: 600px;
}

.test-buttons{
  display: flex;
  justify-content: flex-end;
  margin-top: -55px;
}

.test-buttons button {
  margin: 10px;
  background : #FF0000;

}
.send-event{
  background : #351549 !important;
}

.robo-chat-here{
  overflow: hidden;
}

.robo-container-open {
  height: 550px;
  width: 400px;
  min-width: 400px;
  min-height: 300px;
  background-color: #fff;
  border-radius: 5px 5px 0 0;
  -webkit-box-shadow: 5px 5px 13px -2px #939393;
  box-shadow: 5px 5px 13px -2px #939393;
}

.robo-header {
  min-height: 24px;
  background-color: #351549;
  padding: 20px 15px;
  border-radius: 5px 5px 0 0;
}

.robo-logo {
  width: auto;
  height: 40px;
}

.robo-ai-branding {
  background-color: #fff;
  color: #351549;
  padding: 10px 15px;
  text-align: center;
  font-size: 14px;
}

.debugger{
  background-color: #1E1E1E;
  width: 90%;
  margin-left: 2em;
  border-radius: 5px;
  color: #999999;
  max-height: 552px;
}

.debugger p {
  margin: 1em;
  size: 14px;
}

.debugger-code{
  height: 38%;
  max-height: 38%;
  min-height: 38%;
  border-radius: 5px;
  background-color: #22292B;
  overflow: auto;
}

.event-table thead th{
  border: none;
}

.delete-param{
  border: none;
  background: transparent;
}

.speech-bubble {
  position: relative;
  background: #f3f3f3;
  border-radius: 30px 30px 0 30px;
  margin-left: 10%;
}

.speech-bubble-buttons {
  padding: 15px;
}

.speech-bubble-buttons button {
  margin: 5px;
}

.speech-bubble-robo {
  position: relative;
  background: #351549;
  border-radius: 30px 30px 30px 0px;
  margin-right: 10%;
  min-width: 350px;
}

.speech-bubble-studio {
  max-width: 350px;
  word-wrap: break-word;
  font-size: 14px;
}

.speech-bubble-studio p {
  margin-bottom: 0 !important;
}

.speech-expand {
  border: none;
  background: transparent;
  color: #999;
  outline: none;
}

.collapsable {
  overflow: hidden;
}

.overlay {
  -webkit-box-shadow: inset 0px -25px 39px 1px rgba(53, 21, 73, 100);
  -moz-box-shadow: inset 0px -25px 39px 1px rgba(53, 21, 73, 100);
  box-shadow: inset 0px -25px 39px 1px rgba(53, 21, 73, 100);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.speech-bubble-robo-grey {
  position: relative;
  background: #f3f3f3;
  border-radius: 30px 30px 30px 0px;
  margin-right: 10%;
  min-width: 200px;
  max-width: 200px;
}

.speech-bubble-robo-grey p {
  padding: 10px 30px;
  margin-bottom: 5px;
}

.speech-bubble-robo-grey {
  position: relative;
  background: #f3f3f3;
  border-radius: 30px 30px 30px 0px;
  margin-right: 10%;
  min-width: 200px;
}

.speech-bubble-robo-grey p {
  color: #16181d;
  padding: 10px 30px;
  margin-bottom: 5px;
}

.speech-bubble-robo .command,
.speech-bubble-robo .command h5 {
  color: #fff;
}

.speech-bubble p {
  color: #16181d;
  padding: 10px 30px;
  margin-bottom: 5px;
}
.speech-bubble-robo p,
.speech-bubble-robo ul,
#cc-slider,
.cmsClass {
  color: #fff;
  padding: 10px 30px;
  margin-bottom: 5px;
}

.speech-bubble-robo .videoClass {
  width: 335px;
  padding: 10px 30px;
  margin-bottom: 5px;
}

.speech-bubble-robo ul.bubble-command {
  padding: 10px 50px;
}
.time {
  color: #8a8989;
  text-align: right;
  margin-right: 30px;
  margin-bottom: 10px;
  font-size: 0.8em;
}
.time strong {
  color: #16181d;
}
.time-robo {
  color: #8a8989;
  margin-left: 30px;
  margin-bottom: 10px;
  font-size: 0.8em;
}
.time-robo strong {
  color: #16181d;
}
.field {
  position: fixed;
  width: 400px;
  margin: 0 auto;
  left: 50%;
  margin-left: 125px;
  transform: translate(-50%, 0);
  bottom: 0;
  padding: 15px 30px;
  background-color: #fff;
  z-index: 10;
}

.bot-btn-option {
  background: #ff3366;
  color: #ffffff;
  font-size: 28px;
}

.green{
  color: #63c671;
}

.yellow{
  color: #ffbf00;
}

.red{
  color: #de684b;
}


/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  width: 100%;
}

#sidebar .fa-fw {
  text-align: left;
  width: 30px;
}

#sidebar {
  width: 275px;
  position: fixed;
  top: 75px;
  left: 0;
  height: 100vh;
  font-size: 1rem;
  z-index: 999;
  background: #fff;
  color: #16181d;
  transition: all 0.3s;
  box-shadow: 0 2px 4px 0 rgba(22, 24, 29, 0.1);
}

#sidebar.active {
  margin-left: -275px;
}

#sidebar ul.components {
  padding: 1rem 0;
}

#sidebar ul li a {
  color: #999;
  padding: 1rem 1rem;
  display: block;
  font-size: 1rem;
}

#sidebar ul li a:hover {
  color: #16181d;
}

#sidebar ul li.active > a,
#sidebar a[aria-expanded='true'] {
  color: #fff;
  background: #5c31bc;
}

a[data-toggle='collapse'] {
  position: relative;
}

#sidebar .dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

#sidebar ul ul a {
  padding-left: 40px;
  background: #16181d;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
  width: calc(100% - 275px);
  padding: 90px 15px 60px 15px;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}

#content.active {
  width: 100%;
}
/* ---------------------------------------------------
    FOOTER
----------------------------------------------------- */
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  margin-left: -15px;
  background-color: #fff;
  font-size: 0.9rem;
  padding: 0 1rem 0 1rem;
  z-index: 1;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
/* Extra small devices (portrait phones, less than 576px*/
@media (max-width: 575.98px) {
  img.logo-robo {
    margin: 0.5rem 1rem 0.5rem 1rem;
  }
  .navbar-brand {
    width: 108px;
  }
  .card-body {
    padding: 1rem;
  }
  .display-view .justify-content-start,
  .pagination.justify-content-end {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .export-buttons{
    margin-top: -4em;
  }
}

@media (max-width: 667px) {
  #content {
    padding: 90px 0px 60px 0px;
  }
  .loading {
    margin-left: 0px;
  }
  .footer {
    height: auto;
    margin-left: 0;
  }
}
/* Small devices (landscape phones, less than 768px*/
@media (max-width: 767.98px) {
  .text-right-mobile-center {
    text-align: center;
    margin-bottom: 1rem;
  }
  .text-right-mobile-center .btn {
    width: 100%;
  }
  .breadcrumb{
    margin-top: 1em;
  }
  .app-title {
    margin-top: 0 !important;
    margin-left: 1em !important;
  }

  .single-card{
    margin-bottom: 10em;
  }
  .nav-tabs .nav-item {
    max-width: 8em;
  }
  .mobile-margin {
    margin-left: 2em;
  }
  .mobile-status-assistant {
    flex-wrap: nowrap;
  }
  .filter-mobile {
    padding: 0.12rem 0.25rem;
    font-size: 0.8rem;
    line-height: 1.2;
    border-radius: 0.5rem;
  }
}

/* Medium devices (tablets, less than 992px*/
@media (max-width: 991.98px) {
  #sidebar {
    margin-left: -290px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #content {
    width: 100%;
  }
  #content.active {
    width: calc(100% - 250px);
  }
  #sidebarCollapse span {
    display: none;
  }
  #sidebarCollapse {
    display: none;
  }
  .field {
    position: fixed;
    width: 300px;
    margin: 0 auto;
    left: 50%;
    margin-left: 0px;
    transform: translate(-50%, 0);
    bottom: 0;
    padding: 15px 30px;
    background-color: #fff;
  }
  /* hint-list */
  .label-container {
    position: fixed;
    width: 400px;
    height: 55px;
    bottom: 48px;
    left: 50%;
    margin: 0 auto 0 0;
    transform: translate(-50%, 0);
    padding: 5px 0px;
    background-color: #fff;
    z-index: 10;
    overflow: hidden;
  }
  /* /hint-list */
  .navbar .dropdown-menu.shadow-sm {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.075) !important;
    border-top: 1px solid #000;
  }
  .navbar .dropdown-menu .dropdown-divider {
    height: 0;
    margin: 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
  }
  .navbar .dropdown-item {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.075) !important;
    padding: 0.5rem 0rem;
  }
  .navbar .nav-link {
    display: block;
    padding: 1rem 0.5rem;
  }
  .container-chat {
    margin-left: auto;
    margin-right: auto;
    border-right: 1px #f3f3f3 solid;
    border-left: 1px #f3f3f3 solid;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 0.9rem;
  }
  .dropdown-menu {
    border: none;
    padding: 0 0 1em 4em;
  }
  .nav-toggle-space{
    padding-left: 2em
  }
  .dropdown-divider{
    visibility: hidden;
  }
  .navbar-brand{
    margin: 0 !important;
    margin-right: 1em !important;
  }
  .navbar{
    margin: 0 !important;
    padding: 0 !important;
    flex-direction: row;
    justify-content: flex-start;
  }
}

@media (min-width: 576px) {
  img.logo-robo {
    margin: 1.5rem 1rem 0.5rem 2rem;
  }
}
@media (min-width: 992px) {
  img.logo-robo {
    margin: 1.5rem 1rem 0.5rem 2rem;
  }
}
/* Large devices (desktops, less than 1200px*/
@media (max-width: 1199.98px) {
}

/* ---------------------------------------------------
    NVD3 Charts
----------------------------------------------------- */
.nv-x text {
  fill: #999;
  font-family: 'Roboto', sans-serif;
  font-size: 80%;
}

.nv-y text {
  fill: #999;
  font-family: 'Roboto', sans-serif;
  font-size: 80%;
}

.nv-chart {
  height: 100%;
  width: 100%;
}

.nv-multiBarHorizontalChart {
  height: 100%;
  width: 100%;
}

.robo-chart-tooltip {
  padding: 5px;
}

.top-bots-chart-tooltip .bot-name,
.avg-confidence-chart-tooltip .date,
.bot-conversations-chart-tooltip .date {
  font-weight: 300;
  display: block;
}

.top-bots-chart-tooltip .confidence-label,
.avg-confidence-chart-tooltip .confidence-label,
.bot-conversations-chart-tooltip .label {
  display: inline-block;
  margin-right: 1em;
  color: #888;
}

.channel-provider-logo,
.channel-logo {
  width: 64px;
}

.channel-table {
  width: 32px;
}
/* ---------------------------------------------------
    CAROUSEL
----------------------------------------------------- */


.slick-arrow{
  border:none !important;
  outline:none !important;
}

.slider{
outline: transparent;
max-width: 300px;
}

.slick-content{
display: flex !important;
flex-direction: column !important;
align-items: center !important;
padding-top: 30px;
outline: none;
}

.slick-dots li{
padding: 3px 0 !important;
margin:unset;
}

.slick-slider{
width: 70% !important;
}

.slick-dots{
bottom:0px !important;
}

.slick-button{
max-height: 201px;
max-width: 50%;
border: none;
outline: none;
background-color: transparent;
display: flex;
justify-content: center;
cursor: pointer;
}

.slick-image{
max-height: 100%;
max-width: 100%;
object-fit: scale-down;
align-self: center;
cursor: pointer;
z-index: -1;
position:relative;
padding-bottom: 30px
}

.robo-button {
  color: #fff !important;
  line-height: 1.4;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ff3366 !important;
  border: 0 !important;
  margin: 0;
  text-decoration: none;
}

#cc-slider {
  text-align: center;
  margin: 0 auto;
  max-width: 850px;
  position: relative;
}
#cc-slider input {
  display: none;
}
#cc-slides article img {
  width: 100%;
  height: 205px;
}
#cc-slides .inner {
  width: 500%;
  line-height: 0;
}
#overflow {
  width: 100%;
  overflow: hidden;
}
#cc-slides article {
  width: 20%;
  float: left;
}
#controls {
  margin: -40% 0 0 -9%;
  width: 119%;
  height: 108px;
}
#controls label,
a {
  color: #999;
  cursor: pointer;
  text-decoration: none;
}
#controls label {
  display: none;
  width: 25px;
  height: 25px;
}
#controls label:hover {
  color: #ff3366 !important;
}
#controls label:hover {
  opacity: 0.8;
}
#active {
  margin: 23% 0 0;
  text-align: center;
}
#active label {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #f89e67;
}
#active label:hover {
  background: #ccc;
  border-color: #777 !important;
}
.cctooltip {
  color: #ffffff;
  font-style: italic;
  line-height: 20px;
  margin-top: 172px;
  opacity: 0;
  position: absolute;
  text-align: left;
  -webkit-transform: translateZ(0);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.cctooltip h3 {
  color: #ffffff;
  margin: 0 0 5px;
  font-weight: normal;
  font-size: 16px;
  font-style: normal;
  background: none repeat scroll 0 0 #222222;
  padding: 5px;
  max-width: 271px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.cctooltip h4 {
  color: #ffffff;
  margin: 0 0 5px;
  font-weight: normal;
  font-size: 14px;
  font-style: normal;
  background: none repeat scroll 0 0 #222222;
  padding: 5px;
  text-align: right;
  width: 160px;
}
#cc-slides .inner {
  -webkit-transform: translateZ(0);
  -webkit-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  -ms-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1); /* easeInOutQuart */

  -webkit-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -ms-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(
    0.77,
    0,
    0.175,
    1
  ); /* easeInOutQuart */
}
#cc-slider {
  -webkit-transform: translateZ(0);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
#controls label {
  -webkit-transform: translateZ(0);
  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}
/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.robo-dot-flashing {
  position: relative;
  margin-left: 30px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ccc;
  color: #ccc;
  animation: robo-dot-flashing 0.5s infinite linear alternate;
  animation-delay: 0.3s;
}

.robo-dot-flashing::before,
.robo-dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.robo-dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ccc;
  color: #ccc;
  animation: robo-dot-flashing 0.5s infinite alternate;
  animation-delay: 0s;
}

.robo-dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ccc;
  color: #ccc;
  animation: robo-dot-flashing 0.5s infinite alternate;
  animation-delay: 0.6s;
}

@keyframes robo-dot-flashing {
  0% {
    background-color: #999;
  }
  50%,
  100% {
    background-color: #ccc;
  }
}

.robo-current-state-badge {
  padding: 10px 22px;
  background: #ffe6ec;
  color: #ff3366;
}

.youtube{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  padding-bottom: 25px;
}

.app-title {
  margin-left: 50px;
  margin-top: 5px;
}


/* ---------------------------------------------------
   Upgrade
----------------------------------------------------- */

.app-upgrade {
  margin-left: 50px;
}

.upgrade-card-header {
  background-color: #351549;
  color: #fff;
}

.upgrade-card-header h3 , .upgrade-card-header h4 ,.upgrade-card-header h6  {
  color: #fff;
  font-weight: 900;
}

.btn-group-plan {
  display: block;
}

.btn-plan input {
  display: none;
}

.hubspot {
  margin: auto;
  width: 10%;
}



.stat-box{
display:flex;
flex-wrap:wrap
}

.stat-box > .card {
  margin-right: 5px;
  margin-left: 5px;
}

.stat-box>* {
  flex-basis: 32.2%
}

@media (max-width: 1235px) {
  .stat-box>* {
    flex-basis: 31%
  }
}

@media (max-width: 992px) {
  .stat-box>* {
    flex-basis: 48%
  }
}

@media (max-width: 547px) {
  .stat-box>* {
    flex-basis: 46%
  }
}

.disableTab {
  color: rgb(221, 221, 221)!important;
  cursor: not-allowed;
  pointer-events: none;
}


#jsd-widget {
  height: 80px;
  width: 129px;
  position: fixed;
  border: 0px;
  bottom: 0px;
  left: 0px !important;
  z-index: 2147483647;
  transition: height 0s ease 0s;
}
